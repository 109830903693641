import { useState } from 'react';

const vaccinationDetails = [
  {
    vaccination: "Zeckenimpfung",
    details: `Preis: CHF 47.35 (Impfstoff) zuzüglich CHF 20.00 (Impfservice)
Alter: Ab 16 Jahren
Die Zeckenimpfung wird insgesamt dreimal verabreicht. Es wird jedoch unterschieden zwischen dem normalen- und dem Schnellimpfschema.`,
  },
  {
    vaccination: "Grippeimpfung",
    details: `Preis: CHF 13.70 (Impfstoff) zuzüglich CHF 20.00 (Impfservice)
Alter: Ab 16 Jahren
Die Impfung ist saisonal abhängig (Start meist im Oktober / November bis Februar)`,
  },
  {
    vaccination: "Herpes Zoster",
    details: `Preis: CHF 174.65 (Impfstoff) zuzüglich CHF 20.00 (Impfservice)
Alter: Ab 18 Jahren, empfohlen wird die Impfung ab dem 65. Lebensjahr`,
  },
  {
    vaccination: "Diphtherie, Tetanus und Pertussis",
    details: `Preis: CHF 36.30 (Impfstoff) zuzüglich CHF 20.00 (Impfservice)
Alter: Ab 16 Jahren`,
  },
  {
    vaccination: "Hepatitis A + B",
    details: `Hepatitis A Preis: CHF 53.00 (Impfstoff) zuzüglich CHF 20.00 (Impfservice)
Die Hepatitis A Impfung wird in zwei Dosen verabreicht.`,
  },
  {
    vaccination: "Impfung gegen Mumpf, Masern und Röteln",
    details: `Preis: CHF 36.60 zuzüglich CHF 20.00 (Impfservice) Die Impfung gegen Mumpf, Masern und Röteln wird in zwei Dosen verabreicht.`,
  },
];

export default function VaccFAQs() {
  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  return (
    <div className="bg-gradient-to-r from-blue-500 via-indigo-500 to-purple-500 py-20">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mb-12 text-center">
          <h2 className="text-4xl font-extrabold text-white">Impfungen</h2>
          <p className="mt-4 text-lg text-gray-200 max-w-2xl mx-auto">
            Unser engagiertes Team in der Apotheke steht Ihnen zur Verfügung, um sicherzustellen, dass Sie die notwendigen Impfungen schnell und bequem erhalten.
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {vaccinationDetails.map((faq, index) => (
            <div
              key={index}
              className={`relative bg-white rounded-xl shadow-lg p-6 transform transition-all duration-300 ${
                openFAQ === index ? '' : ''
              }`}
            >
              <div className="flex items-center justify-between">
                <h3 className="text-xl font-semibold text-gray-900">{faq.vaccination}</h3>

                <button
                  className="text-indigo-600 hover:text-indigo-800 focus:outline-none"
                  onClick={() => toggleFAQ(index)}
                >
                  {openFAQ === index ? '-' : '+'}
                </button>
              </div>

              <div className={`overflow-hidden transition-all duration-300 ${openFAQ === index ? 'max-h-full' : 'max-h-0'}`}>
                {openFAQ === index && (
                  <p className="mt-4 text-gray-600">{faq.details}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
