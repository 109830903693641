'use client';

import { useState } from 'react';
import { Dialog } from '@headlessui/react'; // Correct import
import { FiHelpCircle } from 'react-icons/fi'; // Question mark icon for the floating button

export default function InfoModal() {
  const [open, setOpen] = useState(false); // Initially, the modal is closed

  return (
    <div>
      {/* Floating button in the Bottom Right */}
      <button
        onClick={() => setOpen(true)}
        className="fixed bottom-6 right-6 bg-[#4D7F6E] text-white p-4 rounded-full shadow-lg transition hover:shadow-2xl hover:scale-110 duration-300 ease-in-out focus:outline-none z-50"
        aria-label="Open modal"
      >
        <FiHelpCircle size={24} /> {/* Question mark icon for the button */}
      </button>

      {/* Modal */}
      <Dialog open={open} onClose={() => setOpen(false)} className="relative z-50">
        {/* Full-screen backdrop */}
        <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true" />
        {/* Modal container */}
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Dialog.Panel className="relative transform overflow-hidden rounded-xl bg-white px-8 pb-6 pt-6 text-left shadow-2xl transition-all sm:max-w-md w-full sm:p-8">
              <div className="relative">
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-2xl font-bold leading-7 text-gray-800">
                    How can we help you?
                  </Dialog.Title>
                  <div className="mt-4 text-left">
                    <ul className="list-none text-sm text-gray-600 space-y-4">
                      <li>
                        <strong>Track Your Order:</strong> Track your order{" "}
                        <a href="/track-order" className="text-[#60A465] hover:underline">here</a>.
                      </li>
                      <li>
                        <strong>Contact Us:</strong> For assistance, email us at{" "}
                        <a href="mailto:support@pharmacy.com" className="text-[#60A465] hover:underline">support@pharmacy.com</a>{" "}
                        or call us at{" "}
                        <a href="tel:+123456789" className="text-[#60A465] hover:underline">+123 456 789</a>.
                      </li>
                      <li>
                        <strong>FAQs:</strong> Common questions about our service can be found on our{" "}
                        <a href="/faqs" className="text-[#60A465] hover:underline">FAQ page</a>.
                      </li>
                      <li>
                        <strong>Prescription Upload:</strong> To order prescription medication, upload your prescription{" "}
                        <a href="/upload-prescription" className="text-[#60A465] hover:underline">here</a>.
                      </li>
                      <li>
                        <strong>Return Policy:</strong> Learn more about returns and refunds{" "}
                        <a href="/return-policy" className="text-[#60A465] hover:underline">here</a>.
                      </li>
                      <li>
                        <strong>Shipping Information:</strong> View our shipping policies and estimated delivery times{" "}
                        <a href="/shipping" className="text-[#60A465] hover:underline">here</a>.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="mt-8 sm:mt-10">
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  className="inline-flex w-full justify-center rounded-lg bg-[#60A465] px-5 py-3 text-sm font-semibold text-white shadow-lg hover:bg-[#519d55] transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#60A465]"
                >
                  Close
                </button>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
