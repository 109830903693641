// src/components/ResetPassword.js
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { resetPassword } from '../services/apiService'; // You will create this service

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Extract query parameters (id and token) from the URL
  const query = new URLSearchParams(useLocation().search);
  const id = query.get('id');
  const token = query.get('token');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      setErrorMessage("Passwords don't match!");
      return;
    }
    try {
      const response = await resetPassword(id, token, newPassword, confirmNewPassword);
      setSuccessMessage('Password has been reset successfully!');
      setErrorMessage('');
    } catch (error) {
      setErrorMessage('Error resetting password. Please try again.');
      setSuccessMessage('');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center py-6 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-lg space-y-8">
        <div className="bg-white p-10 rounded-lg shadow-lg">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 mb-6">Reset Your Password</h2>
          <p className="text-center text-gray-600 mb-6">
            Enter your new password below. Make sure both fields match. Once you submit, your password will be updated.
          </p>
          <form onSubmit={handleSubmit} className="space-y-8">
            <div className="rounded-md shadow-sm space-y-6">
              <div>
                <label htmlFor="newPassword" className="sr-only">
                  New Password
                </label>
                <input
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  required
                  className="appearance-none rounded-md relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-[#436F5E] focus:border-[#436F5E] sm:text-lg"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="confirmNewPassword" className="sr-only">
                  Confirm New Password
                </label>
                <input
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  type="password"
                  required
                  className="appearance-none rounded-md relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-[#436F5E] focus:border-[#436F5E] sm:text-lg"
                  placeholder="Confirm New Password"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-3 px-6 border border-transparent text-lg font-medium rounded-md text-white bg-[#436F5E] hover:bg-[#3a5f50] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#436F5E]"
              >
                Reset Password
              </button>
            </div>
          </form>

          {successMessage && (
            <p className="text-green-500 text-sm mt-4 text-center">{successMessage}</p>
          )}
          {errorMessage && (
            <p className="text-red-500 text-sm mt-4 text-center">{errorMessage}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
