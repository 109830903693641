import Nav from "../components/Nav"; 
import UserProfile from "../components/Profile/UserProfile";

function ProfilePage() {
    return (
      <div>
       <Nav />
       <UserProfile />
      </div>
    );
  }
  
  export default ProfilePage;
  