
import Nav from "../components/Nav"; 
import Footer from '../components/Footer';
import Payment from '../components/Shop/Payment';


function PaymentPage() {
    return (
      <div>
       <Nav />
       <Payment />
       <Footer />
      </div>
    );
  }
  
  export default PaymentPage;
  