import Nav from "../components/Nav"; 
import Footer from '../components/Footer';
import AboutUs from '../components/AboutUs';


function AboutUsPage() {
    return (
      <div>
       <Nav />
       <AboutUs />
       <Footer />
      </div>
    );
  }
  
  export default AboutUsPage;
  