// src/App.js
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Products from './pages/Products';
import ProductDetailsPage from './pages/ProductDetailsPage';
import CartPage from './pages/CartPage';
import CheckoutPage from './pages/CheckoutPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import CategoryPage from './pages/CategoryPage';
import OrderDetailsPage from './pages/OrderDetailsPage';
import AppointmentManagment from './pages/AppointmentManagment';
import BookAppointmentPage from './pages/BookAppointmentPage';
import VacinationsPage from './pages/VacinationsPage';
import VacAppointmentPage from './pages/VacAppointmentPage';
import ProfilePage from './pages/ProfilePage';
import ProtectedRoute from './components/ProtectedRoute';
import TestPage from './pages/TestPage';
import BlogPage from './pages/BlogPage';
import UserFavoritesPage from './pages/UserFavoritesPage';
import SearchResultsPage from './pages/SearchResultPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import VerifyEmailPage from './pages/VerifyEmailPage';
import EmailSentPage from './pages/EmailSentPage';
import PaymentPage from './pages/PaymentPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import FAQPage from './pages/FAQPage';
import AboutUsPage from './pages/AboutUsPage';

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/product/:pharmacode" element={<ProductDetailsPage />} /> {/* Updated Route for Product Details */}
          <Route path="/cartpage" element={<CartPage />} />
          <Route path="/checkoutpage" element={<CheckoutPage />} />
          <Route path="/loginpage" element={<LoginPage />} />
          <Route path="/registerpage" element={<RegisterPage />} />
          <Route path="/categorypage" element={<CategoryPage />} />
          <Route path="/orderdetailspage" element={<OrderDetailsPage />} />
          <Route path="/appointmentmanagment" element={<AppointmentManagment />} />
          <Route path="/bookappointment" element={<BookAppointmentPage />} />
          <Route path="/vacinationspage" element={<VacinationsPage />} />
          <Route path="/vacappointment" element={< VacAppointmentPage />} />
          <Route path="/testpage" element={< TestPage />} />
          <Route path="/blogs" element={< BlogPage />} />
          <Route path="/UserFavoritesPage" element={< UserFavoritesPage />} />
          <Route path="/searchresults" element={< SearchResultsPage/>} />
          <Route path="/forgot-password" element={< ForgotPasswordPage/>} />
          <Route path="/reset-password" element={< ResetPasswordPage/>} />
          <Route path="/verify-email" element={<VerifyEmailPage />} />
          <Route path="/email-sent" element={<EmailSentPage />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
          <Route path="/faqs" element={<FAQPage />} />
          <Route path="/aboutus" element={<AboutUsPage />} />
          <Route
            path="/profilepage"
            element={
              <ProtectedRoute>
                <ProfilePage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
