import React from "react";
import { FaSyringe, FaCalendarAlt, FaNotesMedical, FaStethoscope } from "react-icons/fa";

const LabHero = () => {
  return (
    <div className="relative w-full h-[95vh] overflow-hidden">
      <video
        autoPlay
        loop
        muted
        className="absolute inset-0 w-full h-full object-cover"
      >
        <source src="videos/running.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Background Overlay to isolate text and icons from video */}
      <div className="absolute inset-0 bg-black bg-opacity-50 z-0"></div>

      <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white">
        <h1 className="text-4xl md:text-6xl font-bold mb-4 lg:mt-[-20vh] text-white">
          Welcome to Our Lab
        </h1>
        <p className="text-lg md:text-2xl mb-6 text-white">
          Your health, our priority. Book your appointment today.
        </p>
        
        {/* New Button Below Paragraph */}
        <button className="text-lg font-semibold text-white underline rounded-lg shadow-full">
          Learn More
        </button>
      </div>

      {/* Circles with triangles seamlessly inside thicker white border */}
      <div className="absolute bottom-8 md:bottom-16 lg:bottom-24 left-1/2 transform -translate-x-1/2 flex space-x-6 md:space-x-12 lg:space-x-16 z-10">
        {/* Circle 1 - Vaccination */}
        <div className="relative flex flex-col items-center justify-center">
          <div className="relative w-20 h-20 md:w-28 md:h-28 lg:w-36 lg:h-36 rounded-full border-4 border-white bg-gradient-to-r from-blue-400 to-blue-600 flex items-center justify-center shadow-xl transform hover:scale-105 transition duration-300 ease-in-out">
            <FaSyringe className="text-2xl md:text-3xl lg:text-4xl text-white" />
          </div>
          {/* Triangle below the circle with white border */}
          <div className="w-0 h-0 border-l-8 border-r-8 border-t-8 border-transparent border-t-blue-600 lg:mt-[-2px] relative">
            <div className="absolute -left-2 -top-2 border-l-8 border-r-8 border-t-8 border-transparent border-t-white"></div>
          </div>
        </div>

        {/* Circle 2 - Book Appointment */}
        <div className="relative flex flex-col items-center justify-center">
          <div className="relative w-20 h-20 md:w-28 md:h-28 lg:w-36 lg:h-36 rounded-full border-4 border-white bg-gradient-to-r from-teal-400 to-teal-600 flex items-center justify-center shadow-xl transform hover:scale-105 transition duration-300 ease-in-out">
            <FaCalendarAlt className="text-2xl md:text-3xl lg:text-4xl text-white" />
          </div>
          <div className="w-0 h-0 border-l-8 border-r-8 border-t-8 border-transparent border-t-teal-600 lg:mt-[-2px] relative">
            <div className="absolute -left-2 -top-2 border-l-8 border-r-8 border-t-8 border-transparent border-t-white"></div>
          </div>
        </div>

        {/* Circle 3 - Medical Records */}
        <div className="relative flex flex-col items-center justify-center">
          <div className="relative w-20 h-20 md:w-28 md:h-28 lg:w-36 lg:h-36 rounded-full border-4 border-white bg-gradient-to-r from-purple-400 to-purple-600 flex items-center justify-center shadow-xl transform hover:scale-105 transition duration-300 ease-in-out">
            <FaNotesMedical className="text-2xl md:text-3xl lg:text-4xl text-white" />
          </div>
          <div className="w-0 h-0 border-l-8 border-r-8 border-t-8 border-transparent border-t-purple-600 lg:mt-[-2px] relative">
            <div className="absolute -left-2 -top-2 border-l-8 border-r-8 border-t-8 border-transparent border-t-white"></div>
          </div>
        </div>

        {/* Circle 4 - Consultation */}
        <div className="relative flex flex-col items-center justify-center">
          <div className="relative w-20 h-20 md:w-28 md:h-28 lg:w-36 lg:h-36 rounded-full border-4 border-white bg-gradient-to-r from-indigo-400 to-indigo-600 flex items-center justify-center shadow-xl transform hover:scale-105 transition duration-300 ease-in-out">
            <FaStethoscope className="text-2xl md:text-3xl lg:text-4xl text-white" />
          </div>
          <div className="w-0 h-0 border-l-8 border-r-8 border-t-8 border-transparent border-t-indigo-600 lg:mt-[-2px] relative">
            <div className="absolute -left-2 -top-2 border-l-8 border-r-8 border-t-8 border-transparent border-t-white"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LabHero;
