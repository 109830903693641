import React from 'react';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import Register from '../components/Authentication/Register';
import Test from '../components/Shop/test';

function TestPage() {
  return (
    <div>
      <Nav />
      <Test />
    </div>
  );
}

export default TestPage;
