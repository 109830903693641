import { XMarkIcon } from '@heroicons/react/20/solid'

export default function Categories() {
  // Define the categories
  const categories = ["Medikamente & Behandlung", "Ernährung & Gesundheit", "Baby & Eltern", "Beauty & Körperpflege", "Haushalt & Reinigung", "Mehr"];

  return (
    <div>
      {/* Header with Dismiss button */}
      <div className="mx-6 border-t border-gray-300"></div>
      <div className="flex items-center justify-between px-6 py-4 sm:px-3.5">
        
        {/* Center categories horizontally and add more space between them */}
        <div className="flex items-center justify-center gap-x-32 flex-wrap w-full">
          {categories.map((category, index) => (
            <div key={index} className="text-sm font-medium text-[#4D7F6E]"> {/* Custom color applied */}
              {category}
            </div>
          ))}
        </div>

        {/* Dismiss button */}
      </div>

      {/* Divider */}
      <div className="mx-6 border-t border-gray-300"></div>
    </div>
  );
}
