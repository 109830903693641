import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className="border-b border-gray-200">
      <button
        onClick={toggleOpen}
        className="flex items-center justify-between w-full py-4 text-left"
      >
        <span className="text-lg font-semibold text-gray-800">{question}</span>
        <span className="ml-2 text-gray-500">
          {isOpen ? <FaChevronUp /> : <FaChevronDown />}
        </span>
      </button>
      {isOpen && <p className="mt-2 text-gray-600">{answer}</p>}
    </div>
  );
};

export default function FAQ() {
  const faqs = [
    {
      question: "How do I place an order?",
      answer:
        "You can place an order by browsing our products, adding items to your cart, and proceeding to checkout where you'll enter your shipping and payment information.",
    },
    {
      question: "What payment methods are accepted?",
      answer:
        "We accept all major credit cards, PayPal, and other secure payment methods to ensure your data is safe.",
    },
    {
      question: "Can I track my order?",
      answer:
        "Yes! Once your order is shipped, you'll receive an email with the tracking details so you can follow your package.",
    },
    {
      question: "Do you offer prescription services?",
      answer:
        "Yes, you can upload your prescription during checkout, and we'll process your order according to your doctor's prescription.",
    },
    {
      question: "What is your return policy?",
      answer:
        "We offer a 30-day return policy on most products. Please check our return policy page for more details on eligible items and the return process.",
    },
  ];

  return (
    <div className="bg-gray-50 py-12 sm:py-16 lg:py-24">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 text-center sm:text-4xl">
          Frequently Asked Questions
        </h1>
        <p className="mt-4 max-w-3xl mx-auto text-base text-[#3B725C] text-center">
          Find answers to common questions about our pharmacy, products, and services.
        </p>

        <div className="mt-10 space-y-6 max-w-3xl mx-auto">
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
    </div>
  );
}
