import EmailSent from "../components/EmailSent";


function EmailSentPage() {
    return (
      <div>
      <EmailSent />
      </div>
    );
  }
  
  export default EmailSentPage;
  