
import Nav from "../components/Nav"; 
import Footer from '../components/Footer';
import ProductDetails from '../components/Shop/ProductDetails';
import Banner from "../components/Banner";

function ProductDetailsPage() {
    return (
      <div>
       <Banner />
       <Nav />
       <ProductDetails />
       <Footer />
      </div>
    );
  }
  
  export default ProductDetailsPage;
  