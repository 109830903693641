import { useEffect, useRef } from 'react';
import { ShoppingCartIcon, ShieldCheckIcon, UserIcon, GiftIcon } from '@heroicons/react/24/outline';

const timeline = [
  {
    name: '+100,000 Produkte',
    description:
      'Ob für Gsundheit, Schönheits- oder Pflegeprodukte – bi eus findisch immer, was du bruchsch.',
    date: 'Shop',
    icon: ShoppingCartIcon,
  },
  {
    name: 'Schnell und Zuverlässig',
    description:
      'Sicheri und schnelle Diagnosen – vertraue uf üsere Kompetenz i de Labor Diagnostik.',
    date: 'Labor Diagnostik',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Dini persönligi Apotheke',
    description:
      'Individuell uf dii Bedürfnisse agpasst: Mit personalisierte Vorschläg und schnällere Bestellprozess.',
    date: 'Personalisierti Gsundheitsvorsorg',
    icon: UserIcon,
  },
  {
    name: 'Spezielle Aktione für dii',
    description:
      'Mit üse regelmässige Rabattä und Sonderaktione chasch du dini Gsundheitsvorsorg günstiger mache.',
    date: 'Super Offerte',
    icon: GiftIcon,
  },
];

export default function ServicesMobile() {
  const refs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-fade-in-up');
          } else {
            entry.target.classList.remove('animate-fade-in-up'); // Reset the animation when leaving viewport
          }
        });
      },
      {
        threshold: 0.3, // Trigger when 30% of the element is visible
      }
    );

    refs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      refs.current.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, []);

  return (
    <div className="bg-white py-16 mt-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 flex justify-center items-center">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-12 overflow-hidden lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {timeline.map((item, index) => (
            <div
              key={item.name}
              ref={(el) => (refs.current[index] = el)}
              className="opacity-0 transform translate-y-10 transition-all duration-700 ease-out text-center"
            >
              <time
                dateTime={item.dateTime}
                className="flex justify-center items-center text-md font-semibold leading-8 text-[#60a465]"
              >
                {item.date}
              </time>
              <p className="mt-4 text-lg font-semibold leading-8 tracking-tight text-gray-900">{item.name}</p>
              <p className="mt-2 text-base leading-7 text-gray-600">{item.description}</p>

              {/* Icon */}
              <div className="mt-4 flex justify-center">
                <item.icon className="h-12 w-12 text-[#60a465] hover:scale-110 transition-transform duration-300 ease-in-out" />
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Tailwind Animation */}
      <style jsx>{`
        .animate-fade-in-up {
          opacity: 1;
          transform: translateY(0);
        }
      `}</style>
    </div>
  );
}
