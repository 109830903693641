import React, { useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const SearchBar = ({ placeholder = 'Search...' }) => {
  const [searchTerm, setSearchTerm] = useState(''); // Track the search term
  const [isFocused, setIsFocused] = useState(false); // Track focus state for zoom effect
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      // Navigate to the search results page, passing the search term
      navigate(`/searchresults?search=${encodeURIComponent(searchTerm)}`);
    }
  };

  return (
    <form 
      onSubmit={handleSearchSubmit} 
      className="relative w-full"
    >
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)} // Update search term
        onFocus={() => setIsFocused(true)} // Trigger zoom effect on focus
        onBlur={() => setIsFocused(false)} // Remove zoom effect on blur
        className="block w-full bg-gray-100 rounded-full shadow-lg border-gray-100 py-3 pl-4 pr-12 text-sm placeholder-gray-700 focus:border-indigo-500 focus:ring-indigo-500"
        placeholder={placeholder}
      />
      <div className="absolute inset-y-0 right-0 flex items-center pr-3">
        <button
          type="submit"
          className="flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none"
        >
          <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </form>
  );
};

export default SearchBar;
