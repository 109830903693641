
import Nav from "../components/Nav"; 
import Footer from '../components/Footer';
import BookAppointment from "../components/Labor/BookAppointment";


function BookAppointmentPage() {
    return (
      <div>
       <Nav />
       <BookAppointment />
       <Footer />
      </div>
    );
  }
  
  export default BookAppointmentPage;
  