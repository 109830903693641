import React, { useState, useEffect } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

export default function HelpContent() {
  const phrases = ['Kopfweh', 'Pickeligi Huut', 'Ruggeweh'];
  const [currentText, setCurrentText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(150);
  const [isTypingActive, setIsTypingActive] = useState(true);
  const [userInput, setUserInput] = useState(''); // For the user's input

  const navigate = useNavigate(); // Initialize useNavigate

  // Typing effect logic
  useEffect(() => {
    if (!isTypingActive) return;

    const handleTyping = () => {
      const i = loopNum % phrases.length;
      const fullText = phrases[i];

      if (isDeleting) {
        setCurrentText(fullText.substring(0, currentText.length - 1));
      } else {
        setCurrentText(fullText.substring(0, currentText.length + 1));
      }

      if (!isDeleting && currentText === fullText) {
        setTimeout(() => setIsDeleting(true), 1000);
        setTypingSpeed(50);
      } else if (isDeleting && currentText === '') {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
        setTypingSpeed(150);
      }
    };

    const timer = setTimeout(() => {
      handleTyping();
    }, typingSpeed);

    return () => clearTimeout(timer);
  }, [currentText, isDeleting, isTypingActive]);

  // Handle stopping animation and allowing user input
  const handleClick = () => {
    setIsTypingActive(false); // Stop the typing animation
    setCurrentText('Search Symptoms'); // Reset the text to "Search Symptoms"
    setUserInput(''); // Clear the user input field
  };

  // Handle input change
  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  // Handle search submit
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (userInput.trim()) {
      navigate(`/searchresults?search=${encodeURIComponent(userInput)}`);
    }
  };

  return (
    <div className="bg-[#A3B18A] py-12 sm:py-16 lg:py-8 mt-0 shadow-xl relative lg:h-auto h-screen lg:flex lg:items-center lg:justify-between">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 h-full lg:h-auto flex flex-col lg:flex-row lg:gap-10 lg:items-center lg:justify-between">
        
        {/* Left Side Text Content */}
        <div className="max-w-2xl text-center lg:text-left flex flex-col justify-center h-full lg:h-auto">
          <h2 className="text-white text-3xl sm:text-4xl font-bold tracking-tight">
            Du hesch Kopfweh oder zue Nasä?
          </h2>
          <p className="text-white text-lg sm:text-xl mt-4 leading-relaxed">
            Gib dini Beschwerden i d'Suechifeld ii und erhalt Vorschläg für d'passende Medikament und Pflegemittel. Bi üs chasch du nach Symptomen sueche und schnell hilfriichi Produkt finde.
          </p>
          
          {/* Button */}
          <div className="mt-6 flex justify-center lg:justify-start">
            <a
              href="/products"
              className="inline-block px-6 py-3 bg-white text-gray-900 font-semibold text-base sm:text-lg rounded-full shadow-lg hover:bg-gray-100 transition ease-in-out duration-300"
            >
              Go to Shop
            </a>
          </div>
        </div>

        <div className="relative mx-auto lg:ml-10"> 
          <div className="relative w-[300px] h-[300px] bg-white rounded-full flex items-center justify-center shadow-lg"
            style={{
              boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.3)', // Increase shadow intensity
            }}>
            <form onSubmit={handleSearchSubmit} className="bg-gray-200 text-gray-900 px-4 py-2 rounded-full shadow-lg text-lg w-[260px] flex items-center justify-between overflow-hidden">
              {isTypingActive ? (
                <span className="text-center flex-grow overflow-hidden" onClick={handleClick}>
                  {currentText}
                  <span style={blinkCursorStyle}>|</span> {/* Blinking cursor */}
                </span>
              ) : (
                <input
                  type="text"
                  className="bg-transparent flex-grow outline-none focus:outline-none text-gray-900 px-2"
                  value={userInput}
                  onChange={handleInputChange}
                  autoFocus
                  style={{ border: 'none', boxShadow: 'none' }}
                  placeholder="Search Symptoms" 
                />
              )}
              <button type="submit" className="ml-2">
                <FiSearch className="text-gray-700" style={{ fontSize: '24px' }} />
              </button>
            </form>
          </div>

          {/* Handle of the search icon */}
          <div className="absolute bg-white w-12 h-12 rounded-full bottom-[-20px] left-[-20px] shadow-lg"
            style={{
              boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.3)', // Increase shadow intensity
            }}>
          </div>
        </div>
      </div>

      {/* Inline CSS for the blink cursor */}
      <style jsx>{`
        @keyframes blink {
          0%, 100% {
            opacity: 1;
          }
          50% {
            opacity: 0;
          }
        }

        .blink-cursor {
          font-weight: 100;
          font-size: 24px;
          color: black;
          animation: blink 0.7s step-end infinite;
        }
      `}</style>
    </div>
  );
}

// Blink cursor style
const blinkCursorStyle = {
  fontWeight: '100',
  fontSize: '24px',
  color: 'black',
  animation: 'blink 0.7s step-end infinite',
};
