import React from 'react';

const EmailSent = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
      <div className="bg-white p-10 rounded-2xl shadow-2xl w-96 text-center">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">
          Check Your Email
        </h2>
        <p className="text-gray-700 mb-6">
          We’ve sent a confirmation email to your inbox. Please check it to verify your email address and activate your account.
        </p>
      </div>
    </div>
  );
};

export default EmailSent;
