import React from 'react';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import ResetPassword from '../components/ResetPassword';

function ResetPasswordPage() {
  return (
    <div>
      <Nav />
      <ResetPassword />
      <Footer />
    </div>
  );
}

export default ResetPasswordPage;
