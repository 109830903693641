import React from 'react';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import Searchresults from '../components/Shop/SearchResults';

function SearchResultsPage() {
  return (
    <div>
      <Nav />
      <Searchresults />
      <Footer />
    </div>
  );
}

export default SearchResultsPage;
