import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchProductDetails } from '../../services/apiService';
import { StarIcon, HeartIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ProductDetails() {
  const [product, setProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null); // State to track the selected image
  const [isFavorited, setIsFavorited] = useState(false); // State to track favorite status
  const [isZoomed, setIsZoomed] = useState(false); // Track zoom state
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 }); // Track mouse position for zoom
  const { pharmacode } = useParams();

  useEffect(() => {
    const getProductDetails = async () => {
      try {
        const response = await fetchProductDetails(pharmacode);
        setProduct(response.data.product);

        // Set the initial selected image (the first one)
        const firstImage = response.data.product.articles[0]?.images?.F?.[0];
        setSelectedImage(firstImage ? `${process.env.FRONT_DEV_URL}/${firstImage.replace(/\\/g, '/')}` : null);
      } catch (error) {
        console.error('Failed to fetch product details:', error);
      }
    };

    getProductDetails();
  }, [pharmacode]);

  if (!product) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  const article = product.articles[0];
  const images = article.images?.F || []; // Get all F folder images
  const customersAlsoBought = [
    {
      id: 1,
      name: 'Product 1',
      price: '€19.99',
      imageUrl: 'https://via.placeholder.com/150',
    },
    {
      id: 2,
      name: 'Product 2',
      price: '€29.99',
      imageUrl: 'https://via.placeholder.com/150',
    },
    {
      id: 3,
      name: 'Product 3',
      price: '€39.99',
      imageUrl: 'https://via.placeholder.com/150',
    },
    {
      id: 4,
      name: 'Product 4',
      price: '€24.99',
      imageUrl: 'https://via.placeholder.com/150',
    },
  ];

  // Handle mouse movement over the image to set zoom position
  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setZoomPosition({ x, y });
  };

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-12">
          {/* Image gallery */}
          <div className="flex flex-col-reverse">
            <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
              <div className="grid grid-cols-4 gap-4">
                {images.map((image, index) => (
                  <div
                    key={index}
                    className="group relative flex h-24 w-full cursor-pointer items-center justify-center rounded-lg bg-white shadow-md hover:shadow-lg transition-shadow"
                    onClick={() => setSelectedImage(`${process.env.FRONT_DEV_URL}/${image.replace(/\\/g, '/')}`)}
                  >
                    <span className="sr-only">Image {index + 1}</span>
                    <span className="absolute inset-0 overflow-hidden rounded-lg">
                      <img
                        alt=""
                        src={`${process.env.FRONT_DEV_URL}/${image.replace(/\\/g, '/')}`}
                        className="h-full w-full object-contain object-center"
                      />
                    </span>
                  </div>
                ))}
              </div>
            </div>

            {/* Main image with zoom functionality */}
            {selectedImage && (
              <div
                className="relative aspect-w-1 aspect-h-1 w-full rounded-lg overflow-hidden shadow-lg bg-white"
                onMouseMove={isZoomed ? handleMouseMove : null}
                onMouseEnter={() => setIsZoomed(true)}
                onMouseLeave={() => setIsZoomed(false)}
              >
                <img
                  alt={article.description.longDescription}
                  src={selectedImage}
                  className={classNames(
                    'h-full w-full object-contain object-center transition-transform duration-300',
                    isZoomed ? 'scale-150' : 'scale-100'
                  )}
                  style={isZoomed ? { transformOrigin: `${zoomPosition.x}% ${zoomPosition.y}%` } : {}}
                />
              </div>
            )}
          </div>

          {/* Product info */}
          <div className="mt-10 lg:mt-0">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900">{article.description.longDescription}</h1>

            {/* Review Stars */}
            <div className="mt-3 flex items-center space-x-2">
              <div className="flex items-center">
                {[0, 1, 2, 3, 4].map((rating) => (
                  <StarIcon
                    key={rating}
                    className={classNames(
                      rating < 4 ? 'text-yellow-400' : 'text-gray-300',
                      'h-6 w-6 flex-shrink-0'
                    )}
                    aria-hidden="true"
                  />
                ))}
              </div>
              <p className="sr-only">4 out of 5 stars</p>
              <a href="#" className="ml-3 text-sm font-medium text-gray-500 hover:underline">
                117 reviews
              </a>
            </div>

            <div className="mt-5">
              <p className="text-2xl font-medium text-gray-900">{article.prices[0].price} {article.prices[0].type.description}</p>
            </div>

            <div className="mt-4">
              <p className="text-lg text-gray-700 leading-relaxed">{article.description.description}</p>
            </div>

            {/* Add to Bag & Favorite Button */}
            <form className="mt-6 space-y-4">
              <div className="flex items-center space-x-4">
                <button
                  type="submit"
                  className="w-full flex items-center justify-center rounded-lg bg-green-600 px-8 py-3 text-base font-medium text-white hover:bg-green-700 transition-colors focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2"
                >
                  Add to Bag
                </button>

                {/* Favorite Button */}
                <button
                  type="button"
                  className="flex items-center justify-center rounded-lg p-3 bg-gray-100 hover:bg-gray-200"
                  onClick={() => setIsFavorited(!isFavorited)}
                >
                  <HeartIcon
                    aria-hidden="true"
                    className={classNames(
                      isFavorited ? 'text-[#e05257] fill-current' : 'text-[#e05257]',
                      'h-7 w-7'
                    )}
                  />
                  <span className="sr-only">Add to favorites</span>
                </button>
              </div>
            </form>

            {/* Features section */}
            <section className="mt-12">
              <h2 className="text-lg font-medium text-gray-900">Features</h2>
              <div className="mt-4 border-t border-gray-200 pt-4">
                <ul className="space-y-2 text-sm text-gray-600">
                  <li>Pharmacode: {article.pharmacode}</li>
                  <li>Price: {article.prices[0].price}</li>
                  <li>Company: {article.prices[0].company.description}</li>
                  {article.insuranceCode && (
                    <li>Insurance Code: {article.insuranceCode.description}</li>
                  )}
                </ul>
              </div>
            </section>
          </div>
        </div>

        {/* Customers Also Bought Section */}
        <section className="mt-16">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">Customers Also Bought</h2>
          <div className="grid grid-cols-2 gap-6 md:grid-cols-4">
            {customersAlsoBought.map((product) => (
              <div key={product.id} className="group relative bg-white p-4 rounded-lg shadow hover:shadow-lg transition-shadow">
                <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-gray-200">
                  <img
                    src={product.imageUrl}
                    alt={product.name}
                    className="h-full w-full object-cover object-center group-hover:scale-105 transition-transform duration-300"
                  />
                </div>
                <div className="mt-4">
                  <h3 className="text-sm text-gray-700">
                    <a href="#">
                      <span aria-hidden="true" className="absolute inset-0" />
                      {product.name}
                    </a>
                  </h3>
                  <p className="mt-1 text-lg font-medium text-gray-900">{product.price}</p>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}
