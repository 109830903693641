import React, { useState, useEffect } from 'react';
import { Popover } from '@headlessui/react';
import { Link, useNavigate } from 'react-router-dom';

import {
  Bars3Icon,
  ChevronDownIcon,
  ShoppingCartIcon,
  UserCircleIcon,
  ShoppingBagIcon,
  BriefcaseIcon,
  SparklesIcon,
  ScissorsIcon,
  UserGroupIcon,
  CloudIcon,
  ClipboardDocumentCheckIcon,
  DocumentTextIcon,
  SquaresPlusIcon,
  InformationCircleIcon,
  LockClosedIcon,
  BookOpenIcon,
  BuildingStorefrontIcon,
  QuestionMarkCircleIcon
} from '@heroicons/react/24/outline';


import { HeartIcon } from '@heroicons/react/24/solid';

import { getProfile, logoutUser } from '../services/apiService';
import SearchBar from './SearchBar';
import SearchBarCompact from './SearchBarCompact';
import Sidebar from './Sidebar'; 



const solutions = [
  {
    name: 'Prescription Medicines',
    description: 'Order your prescription medicines with ease, delivered straight to your door.',
    href: '#',
    icon: ShoppingBagIcon,  // Icon for prescription meds
  },
  {
    name: 'Over-the-Counter Medicines',
    description: 'Find a wide range of OTC medicines for everyday health and wellness needs.',
    href: '#',
    icon: ClipboardDocumentCheckIcon,  // Valid replacement for ClipboardCheckIcon
  },
  {
    name: 'Vitamins & Supplements',
    description: 'Boost your health with our variety of vitamins and dietary supplements.',
    href: '#',
    icon: SparklesIcon,  // Icon for vitamins
  },
  {
    name: 'Skin Care',
    description: 'Discover skincare products for all types, from acne treatment to anti-aging.',
    href: '#',
    icon: HeartIcon,  // Icon for skincare products
  },
  {
    name: 'Personal Care',
    description: 'Shop for personal care items like shampoos, soaps, and deodorants.',
    href: '#',
    icon: ScissorsIcon,  // Icon for personal care items
  },
  {
    name: 'Baby & Child Care',
    description: 'Everything you need for your baby, from diapers to baby food.',
    href: '#',
    icon: UserGroupIcon,  // Valid replacement for BabyIcon
  },
  {
    name: 'Medical Equipment',
    description: 'Browse our selection of medical equipment like thermometers, blood pressure monitors, and more.',
    href: '#',
    icon: BriefcaseIcon,  // Icon for medical equipment
  },
  {
    name: 'Allergy Relief',
    description: 'Get fast and effective allergy relief with our selection of antihistamines and nasal sprays.',
    href: '#',
    icon: CloudIcon,  // Icon for allergy relief products
  },
];

const callsToAction = [
  { name: 'Browse Health Products', href: '#', icon: ShoppingBagIcon }, 
  { name: 'Request a Prescription', href: '#', icon: DocumentTextIcon },  // Icon for requesting a new prescription
  { name: 'Shop by Category', href: '#', icon: SquaresPlusIcon },
];

const company = [
  { name: 'About Us', href: '/aboutus', icon: InformationCircleIcon },  // About the pharmacy shop
  { name: 'Our Pharmacists', href: '#', icon: UserGroupIcon },  // Information about pharmacists
  { name: 'Careers', href: '#', icon: BriefcaseIcon },  // Jobs at the pharmacy
  { name: 'Privacy Policy', href: '/privacypolicy', icon: LockClosedIcon },  // Link to privacy policy
];


const resources = [
  { name: 'Health Articles', href: '#', icon: BookOpenIcon },  // Health-related articles
  { name: 'Partner Pharmacies', href: '#', icon: BuildingStorefrontIcon },  // Information about partner pharmacies
  { name: 'User Guides', href: '#', icon: DocumentTextIcon },  // Guides for using the website or services
  { name: 'FAQs', href: '/faqs', icon: QuestionMarkCircleIcon },  // Frequently asked questions section
];


export default function Nav() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        setIsLoggedIn(true);
        try {
          const profileData = await getProfile();
          setUser(profileData.data.user);
        } catch (error) {
          console.error('Error fetching profile:', error);
        }
      }
    };

    fetchUserProfile();
  }, []);

  const handleLogout = () => {
    logoutUser();
    setIsLoggedIn(false);
    setUser(null);
    navigate('/loginpage');
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Popover className={`sticky top-0 z-50 transition-all duration-300 ${isScrolled ? 'bg-white shadow-lg py-3' : 'bg-white py-2'}`}>
      <div className="relative z-20">
      <div className="mx-auto flex max-w-8xl items-center justify-between px-6 sm:py-0 custom:justify-between custom:space-x-10 lg:space-x-12 lg:px-8">
          <div className="flex items-center justify-center">
            <a href="/" className="flex">
              <span className="sr-only">Your Company</span>
              <img
                alt="Company Logo"
                src="/images/logo.jpg"
                className={`transition-all duration-300 ${isScrolled ? 'h-14 sm:h-20' : 'h-16 sm:h-20 lg:h-24'} w-auto max-w-none`} // Dynamic sizing on scroll
              />
            </a>
          </div>
          <div className="ml-auto flex items-center space-x-5 custom:hidden">
            {/* Search icon for small screens */}
            <div className="block search-size:hidden">
              <SearchBarCompact />
            </div>
            {/* Menu icon */}
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-gray-100 p-2 text-gray-500 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#53a751]">
              <span className="sr-only">Open menu</span>
              <Bars3Icon aria-hidden="true" className="h-8 w-8" />
            </Popover.Button>
          </div>
          <div className="hidden custom:flex custom:flex-1 custom:items-center custom:justify-between w-full">
            <Popover.Group as="nav" className="flex space-x-10">
              <Popover>
                <Popover.Button className="group inline-flex items-center rounded-md bg-white text-base font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-[#53a751] focus:ring-offset-2 data-[open]:text-gray-900">
                  <span>Menu</span>
                  <ChevronDownIcon
                    aria-hidden="true"
                    className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-data-[open]:text-gray-600 group-data-[open]:group-hover:text-gray-500"
                  />
                </Popover.Button>
                <Popover.Panel
                  transition
                  className="absolute inset-x-0 top-full z-10 hidden transform bg-white shadow-lg transition data-[closed]:-translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in md:block"
                >
                  <div className="mx-auto grid max-w-7xl gap-y-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-12 xl:py-16">
                    {solutions.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-m-3 flex flex-col justify-between rounded-lg p-3 hover:bg-gray-50"
                      >
                        <div className="flex md:h-full lg:flex-col">
                          <div className="flex-shrink-0">
                            <span className="inline-flex h-10 w-10 items-center justify-center rounded-md bg-[#53a751] text-white sm:h-12 sm:w-12">
                              <item.icon aria-hidden="true" className="h-6 w-6" />
                            </span>
                          </div>
                          <div className="ml-4 md:flex md:flex-1 md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                            <div>
                              <p className="text-base font-medium text-gray-900">{item.name}</p>
                              <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                            </div>
                            <p className="mt-2 text-sm font-medium text-[#537b67] lg:mt-4">
                              Learn more
                              <span aria-hidden="true"> &rarr;</span>
                            </p>
                          </div>
                        </div>
                      </a>
                    ))}
                  </div>
                  <div className="bg-gray-50">
                    <div className="mx-auto max-w-7xl space-y-6 px-4 py-5 sm:flex sm:space-x-10 sm:space-y-0 sm:px-6 lg:px-8">
                      {callsToAction.map((item) => (
                        <div key={item.name} className="flow-root">
                          <a
                            href={item.href}
                            className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 hover:bg-gray-100"
                          >
                            <item.icon aria-hidden="true" className="h-6 w-6 flex-shrink-0 text-gray-400" />
                            <span className="ml-3">{item.name}</span>
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </Popover.Panel>
              </Popover>
              <a href="/products" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Shop
              </a>
              <a href="/vacinationspage" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Labor
              </a>
              <Popover>
                <Popover.Button className="group inline-flex items-center rounded-md bg-white text-base font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-[#53a751] focus:ring-offset-2 data-[open]:text-gray-900">
                  <span>More</span>
                  <ChevronDownIcon
                    aria-hidden="true"
                    className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-data-[open]:text-gray-600 group-data-[open]:group-hover:text-gray-500"
                  />
                </Popover.Button>
                <Popover.Panel
                  transition
                  className="absolute inset-x-0 top-full z-10 hidden transform shadow-lg transition data-[closed]:-translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in md:block"
                >
                  <div className="absolute inset-0 flex">
                    <div className="w-1/2 bg-white" />
                    <div className="w-1/2 bg-gray-50" />
                  </div>
                  <div className="relative mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                    <nav className="grid gap-y-10 bg-white px-4 py-8 sm:grid-cols-2 sm:gap-x-8 sm:px-6 sm:py-12 lg:px-8 xl:pr-12">
                      <div>
                        <h3 className="text-base font-medium text-gray-500">Company</h3>
                        <ul role="list" className="mt-5 space-y-6">
                          {company.map((item) => (
                            <li key={item.name} className="flow-root">
                              <a
                                href={item.href}
                                className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 hover:bg-gray-50"
                              >
                                <item.icon aria-hidden="true" className="h-6 w-6 flex-shrink-0 text-gray-400" />
                                <span className="ml-4">{item.name}</span>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div>
                        <h3 className="text-base font-medium text-gray-500">Resources</h3>
                        <ul role="list" className="mt-5 space-y-6">
                          {resources.map((item) => (
                            <li key={item.name} className="flow-root">
                              <a
                                href={item.href}
                                className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 hover:bg-gray-50"
                              >
                                <item.icon aria-hidden="true" className="h-6 w-6 flex-shrink-0 text-gray-400" />
                                <span className="ml-4">{item.name}</span>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </nav>
                    <div className="bg-gray-50 px-4 py-8 sm:px-6 sm:py-12 lg:px-8 xl:pl-12">
                      <div>
                        <h3 className="text-base font-medium text-gray-500">From the blog</h3>
                        <ul role="list" className="mt-6 space-y-6">
                          {/* Example for blog posts */}
                        </ul>
                      </div>
                      <div className="mt-6 text-sm font-medium">
                        <a href="#" className="text-[#53a751]">
                          View all posts
                          <span aria-hidden="true"> &rarr;</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Popover>
            </Popover.Group>

            <div className="hidden search-size:block ml-10 flex-grow max-w-lg mx-auto px-8">
              <SearchBar />
            </div>
            
            <div className="flex items-center md:ml-12">
            <div className="block search-size:hidden mr-5">
              <SearchBarCompact />
            </div>
              {!isLoggedIn ? (
                <>
                  <a href="/loginpage" className="ml-4 text-base font-medium text-gray-500 hover:text-gray-900">
                    Sign in
                  </a>
                  <a
                    href="/registerpage"
                    className="ml-8 inline-flex items-center justify-center rounded-full border border-transparent bg-[#4D7F6E] px-7 py-2 text-base font-semibold text-white shadow-lg hover:bg-[#4C8350]"
                  >
                    Sign up
                  </a>
                  <a href="/cartpage" className="ml-6 p-2 bg-gray-100 rounded-lg text-gray-500 shadow-lg hover:text-gray-900 hover:bg-gray-200">
                    <ShoppingCartIcon className="h-6 w-6" aria-hidden="true" />
                  </a>
                </>
              ) : (
                <>
                  <span className="ml-4 text-base font-medium text-gray-500">
                    {user && `${user.name} ${user.surname}`}
                  </span>

                  <a href="/profilepage" className="ml-6 text-gray-500 hover:text-gray-900">
                    <UserCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </a>

                  <Link to="/userfavoritespage" className="ml-6 text-gray-500 hover:text-gray-900">
                    <HeartIcon className="h-6 w-6" aria-hidden="true" />
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Sidebar component */}
      <Sidebar solutions={solutions} callsToAction={callsToAction} company={company} resources={resources} />
    </Popover>
  );
}
