const posts = [
  {
    id: 1,
    title: 'Top 10 Essential Health Products for Your Family',
    href: '#',
    description:
      'Ensure your family stays healthy with these must-have products in your medicine cabinet. From vitamins to first aid essentials, we cover everything you need.',
    imageUrl:
      'images/family.jpg',
  },
  {
    id: 2,
    title: '5 Tips for Choosing the Right Skincare Products',
    href: '#',
    description:
      'Learn how to choose skincare products tailored to your skin type. Whether you have dry, oily, or sensitive skin, our tips will help you find the right products.',
    imageUrl:
      'images/skincaredrop.jpg',
  },
  {
    id: 3,
    title: 'How to Manage Seasonal Allergies with Over-the-Counter Medicine',
    href: '#',
    description:
      'Seasonal allergies can be tough. Find out which over-the-counter allergy medications are most effective in managing your symptoms this season.',
    imageUrl:
      'images/allergie.jpg',
  },
];

export default function Example() {
  return (
    <div className="bg-white py-24 sm:py-0">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article
              key={post.id}
              className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-56 sm:pt-36 lg:pt-56" // Adjusted padding to make it shorter and wider
            >
              <img alt="" src={post.imageUrl} className="absolute inset-0 -z-10 h-full w-full object-cover" />
              <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
              <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

              <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                <a href={post.href}>
                  <span className="absolute inset-0" />
                  {post.title}
                </a>
              </h3>
              <p className="mt-2 text-sm text-gray-300">{post.description}</p>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
}
