import { MapPinIcon, PhoneIcon } from '@heroicons/react/20/solid'

export default function Banner() {
  return (
    <div>
      <div className="flex items-center justify-center bg-white px-6 py-2.5 sm:px-3.5"> {/* Flexbox with justify-center */}
        <p className="text-sm leading-6 text-black flex items-center whitespace-nowrap">
          <a href="https://goo.gl/maps/your-pharmacy-location" target="_blank" rel="noopener noreferrer" className="flex items-center">
            <strong className="font-semibold">Dreirosen Apotheke</strong>
            <svg viewBox="0 0 2 2" aria-hidden="true" className="mx-2 inline h-0.5 w-0.5 fill-current">
              <circle r={1} cx={1} cy={1} />
            </svg>
            <MapPinIcon className="inline h-5 w-5 text-[#4D7F6E] mr-1" aria-hidden="true" />
            <span className="hover:underline">Location</span>
          </a>
          <span className="mx-4">|</span>
          <a href="tel:+1234567890" className="flex items-center">
            <PhoneIcon className="inline h-5 w-5 text-[#4D7F6E] mr-1" aria-hidden="true" />
            <span className="hover:underline">+123 456 7890</span>
          </a>
        </p>
      </div>

      {/* Divider */}
      <div className="mx-6 border-t border-gray-300"></div>
    </div>
  );
}
