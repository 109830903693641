import React, { useEffect, useRef, useState } from 'react';
import { Popover } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

export default function Sidebar({ solutions, callsToAction, company, resources }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);

  // Handle body scroll when the sidebar is open/closed
  useEffect(() => {
    if (isSidebarOpen) {
      document.body.style.overflow = 'hidden'; // Disable background scroll
    } else {
      document.body.style.overflow = ''; // Re-enable background scroll
    }

    return () => {
      document.body.style.overflow = ''; // Ensure body scroll is restored on unmount
    };
  }, [isSidebarOpen]);

  return (
    <Popover.Panel
      transition
      className="absolute inset-x-0 top-0 z-30 origin-top-right transform p-2 transition data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in custom:hidden max-h-[100vh] overflow-y-auto overflow-x-hidden min-h-[100vh]"
      onEnter={() => setIsSidebarOpen(true)}  // Set sidebar as open when it appears
      onExited={() => setIsSidebarOpen(false)}  // Set sidebar as closed when it disappears
    >
      <div
        className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 min-h-[100vh] pt-safe-bottom pb-[env(safe-area-inset-bottom)]"
        ref={sidebarRef}
      >
        {/* Header */}
        <div className="px-5 pb-6 pt-5 sm:pb-8">
          <div className="flex items-center justify-between">
            <div className="-mr-2">
              <Popover.Button className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#53a751]">
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </Popover.Button>
            </div>
          </div>

          {/* Solutions */}
          <div className="mt-6 sm:mt-8">
            <nav>
              <div className="grid gap-7 sm:grid-cols-2 sm:gap-x-4 sm:gap-y-8">
                {solutions.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50"
                  >
                    <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-[#53a751] text-white sm:h-12 sm:w-12">
                      <item.icon aria-hidden="true" className="h-6 w-6" />
                    </div>
                    <div className="ml-4 text-base font-medium text-gray-900">{item.name}</div>
                  </a>
                ))}
              </div>
              <div className="mt-8 text-base">
                <a href="#" className="font-medium text-[#53a751]">
                  View all products
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </div>
            </nav>
          </div>
        </div>

        {/* Footer Links */}
        <div className="px-5 py-6">
          <div className="grid grid-cols-2 gap-4">
            {company.map((item) => (
              <a key={item.name} href={item.href} className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                {item.name}
              </a>
            ))}
            {resources.map((item) => (
              <a key={item.name} href={item.href} className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                {item.name}
              </a>
            ))}
          </div>
          <div className="mt-6">
            <a
              href="#"
              className="flex w-full items-center justify-center rounded-md border border-transparent bg-[#53a751] px-4 py-2 text-base font-medium text-white shadow-sm"
            >
              Sign up
            </a>
            <p className="mt-6 text-center text-base font-medium text-gray-500">
              Existing customer?{' '}
              <a href="#" className="text-[#53a751]">
                Sign in
              </a>
            </p>
          </div>
        </div>
      </div>
    </Popover.Panel>
  );
}
