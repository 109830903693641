'use client';

export default function FullWidthSectionWithVideo() {
  return (
    <div className="relative w-full h-32 lg:h-22 overflow-hidden">
      {/* Video Background */}
      <video
        className="absolute top-0 left-0 w-full h-full object-cover"
        autoPlay
        loop
        muted
      >
        <source src="/videos/leavesfalling.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Content */}
      <div className="relative z-10 flex items-center justify-center h-full bg-black bg-opacity-30">
        <div className="flex items-center justify-center text-center lg:text-left flex-row space-x-4">
          <h2 className="text-white text-lg sm:text-xl lg:text-2xl font-extrabold tracking-wider drop-shadow-lg uppercase">
            Embrace the Season with <span className="text-[#ffb347]">Autumn's Best Deals</span>
          </h2>
          <a
            href="/deals"
            className="inline-flex items-center justify-center text-lg lg:text-2xl font-semibold text-[#ffb347] underline transition duration-300 ease-in-out"
          >
            Shop Now
            <svg
              className="ml-2 h-5 w-5 text-[#ffb347] hover:text-[#e0a240] transition duration-300 ease-in-out"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
}
