import React from 'react';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import Hero from '../components/Home/Hero';
import Services from '../components/Home/Services';
import BestSellers from '../components/Home/BestSellers';
import BlogSection from '../components/Home/BlogSection';
import PhoneSection from '../components/Home/PhoneSection';
import HelpContent from '../components/Home/HelpContent';
import DiscountProducts from '../components/Home/DiscountProducts';
import ServicesMobile from '../components/Home/ServicesMobile';
import HeroMobile from '../components/Home/HeroMobile';
import LabServices from '../components/Home/LabServices';
import OurServices from '../components/Home/OurServices';
import InfoModal from '../components/Home/InfoModal';
import MarketingSection from '../components/Home/MarketingSection';
import Brands from '../components/Home/Brands';
import AboutSection from '../components/Home/AboutSection';
import DeliveryDetails from '../components/Home/DeliveryDetails';
import Categories from '../components/Home/Categories';
import CategoryBox from '../components/Home/CategoryBox';

function Home() {
  return (
    <div>
      <div className="hidden md:block">
      <Banner />
      <InfoModal />
      <Nav />
      <Categories />
      <MarketingSection />
      <HelpContent />
      <CategoryBox />
      <BestSellers /> 
      <DiscountProducts />
      <LabServices />
      <Services />
      <BlogSection />
      <PhoneSection />
      <Footer />
      </div>

      <div className="block md:hidden">
      <Nav />
      <HeroMobile />
      <ServicesMobile />
      <BestSellers /> 
      <DiscountProducts />
      <LabServices />
      <Services />
      <BlogSection />
      <PhoneSection />
      <Footer />
      </div>  
    </div>
  );
}

export default Home;

