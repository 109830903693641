import Nav from "../components/Nav"; 
import Footer from '../components/Footer';
import Blogs from "../components/Blog/Blogs";

function BlogPage() {
    return (
      <div>
       <Nav />
       <Blogs />
       <Footer />
      </div>
    );
  }
  
  export default BlogPage;