
import Nav from "../components/Nav"; 
import Footer from '../components/Footer';
import PrivacyPolicy from '../components/PrivacyPolicy';


function PrivacyPolicyPage() {
    return (
      <div>
       <Nav />
       <PrivacyPolicy />
       <Footer />
      </div>
    );
  }
  
  export default PrivacyPolicyPage;
  