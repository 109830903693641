
import Nav from "../components/Nav"; 
import Footer from '../components/Footer';
import Cart from '../components/Shop/Cart';


function CartPage() {
    return (
      <div>
       <Nav />
       <Cart />
       <Footer />
      </div>
    );
  }
  
  export default CartPage;
  