import React, { useState } from 'react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

const SearchBarCompact = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/searchresults?search=${encodeURIComponent(searchTerm)}`);
      setIsOpen(false);
    }
  };

  return (
    <>
      {/* Search Icon Button in Navbar */}
      <button
        className="text-gray-500 hover:text-gray-900"
        onClick={() => setIsOpen(true)}
      >
        <MagnifyingGlassIcon className="h-8 w-8 mt-2" aria-hidden="true" />
      </button>

      {/* Modal Overlay */}
      {isOpen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-start justify-center pt-10">
          <div className="relative w-full max-w-lg p-6 bg-white rounded-lg shadow-lg transform transition-all ease-in-out duration-300 scale-95">
            <button
              className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 focus:outline-none"
              onClick={() => setIsOpen(false)}
            >
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <form onSubmit={handleSearchSubmit} className="relative flex items-center">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full border border-gray-300 rounded-full py-3 pl-4 pr-12 text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 shadow-sm transition-all duration-200"
                placeholder="Search for products..."
              />
              <button
                type="submit"
                className="absolute right-3 p-2 text-gray-500 hover:text-gray-900 focus:outline-none bg-transparent"
              >
                <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default SearchBarCompact;
