import React, { useEffect, useState } from 'react';
import { getProfile, logoutUser, deleteAccount, editProfile, requestLoggedInPasswordReset } from '../../services/apiService'; // Add requestLoggedInPasswordReset
import { useNavigate } from 'react-router-dom';
import { Dialog } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const UserProfile = () => {
  const [profile, setProfile] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({ name: '', surname: '' });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [password, setPassword] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profileData = await getProfile();
        setProfile(profileData.data.user);
        setFormData({ name: profileData.data.user.name, surname: profileData.data.user.surname });
      } catch (error) {
        console.error('Error fetching profile:', error);
        setError('Failed to fetch profile information.');
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleLogout = () => {
    logoutUser();
    navigate('/loginpage');
  };

  const handleDeleteAccount = async () => {
    if (!password) {
      setError('Password is required to delete account.');
      return;
    }

    try {
      const response = await deleteAccount({ password });
      console.log('Delete account response:', response); // Debugging line
      logoutUser(); // Remove token from localStorage
      navigate('/registerpage');
    } catch (error) {
      console.error('Error deleting account:', error);
      setError('Failed to delete account. Make sure your password is correct.');
    } finally {
      setModalOpen(false);
    }
  };

  const handleEditProfile = async () => {
    try {
      const response = await editProfile(formData);
      console.log('Edit profile response:', response); // Debugging line
      setProfile({ ...profile, ...formData });
      setEditMode(false);
    } catch (error) {
      console.error('Error editing profile:', error);
      setError('Failed to edit profile.');
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const openDeleteModal = () => {
    setError('');
    setModalOpen(true);
  };

  const handleResetPassword = async () => {
    try {
      await requestLoggedInPasswordReset(); // Call API to send reset password email
      setSuccessMessage('Password reset link has been sent to your email.');
      setError('');
    } catch (error) {
      setError('Failed to send password reset link.');
      setSuccessMessage('');
    }
  };

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error && !modalOpen) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  if (!profile) {
    return <div className="text-center">No profile data available.</div>;
  }

  return (
    <div className="bg-gray-50 min-h-screen">
      <header className="bg-gray-100 border-b border-gray-200 px-4 py-4"></header>

      <main className="mx-auto max-w-4xl py-6 sm:px-6 lg:px-8">
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Profile Information</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Name</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {editMode ? (
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-md p-2 w-full"
                    />
                  ) : (
                    profile.name
                  )}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Surname</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {editMode ? (
                    <input
                      type="text"
                      name="surname"
                      value={formData.surname}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-md p-2 w-full"
                    />
                  ) : (
                    profile.surname
                  )}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Email</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{profile.email}</dd>
              </div>
            </dl>
            <div className="bg-white px-4 py-5 sm:px-6 flex items-center justify-between">
              <div>
                <h4 className="text-sm font-medium text-gray-900">Edit Profile</h4>
                <p className="mt-1 text-sm text-gray-500">Update your personal information.</p>
              </div>
              {editMode ? (
                <button
                  onClick={handleEditProfile}
                  className="mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                >
                  Save
                </button>
              ) : (
                <button
                  onClick={() => setEditMode(true)}
                  className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Edit
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="mt-8">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Account Management</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">Manage your account settings.</p>
            </div>
            <div className="border-t border-gray-200">
              <div className="px-4 py-5 sm:p-6 flex items-center justify-between">
                <div>
                  <h4 className="text-sm font-medium text-gray-900">Logout</h4>
                  <p className="mt-1 text-sm text-gray-500">
                    Logging out will end your current session and return you to the login page.
                  </p>
                </div>
                <button
                  onClick={handleLogout}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                  Logout
                </button>
              </div>
              <div className="px-4 py-5 sm:p-6 flex items-center justify-between">
                <div>
                  <h4 className="text-sm font-medium text-gray-900">Reset Password</h4>
                  <p className="mt-1 text-sm text-gray-500">
                    Clicking the button below will send a password reset link to your email.
                  </p>
                </div>
                <button
                  onClick={handleResetPassword}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Send Reset Password Link
                </button>
              </div>
              <div className="px-4 py-5 sm:p-6 flex items-center justify-between bg-gray-50">
                <div>
                  <h4 className="text-sm font-medium text-gray-900">Delete Account</h4>
                  <p className="mt-1 text-sm text-gray-500">
                    Deleting your account will permanently remove all your data. This action cannot be undone.
                  </p>
                </div>
                <button
                  onClick={openDeleteModal}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                  Delete Account
                </button>
              </div>
            </div>
          </div>
        </div>
        {successMessage && <p className="text-green-500 text-center mt-4">{successMessage}</p>}
        {error && <p className="text-red-500 text-center mt-4">{error}</p>}
      </main>

      <Dialog open={modalOpen} onClose={() => setModalOpen(false)} className="relative z-10">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Deactivate account
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to deactivate your account? All of your data will be permanently removed.
                        This action cannot be undone.
                      </p>
                      <input
                        type="password"
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="mt-2 border border-gray-300 rounded-md p-2 w-full"
                      />
                    </div>
                    {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  onClick={handleDeleteAccount}
                  className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                >
                  Deactivate
                </button>
                <button
                  type="button"
                  onClick={() => setModalOpen(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Cancel
                </button>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default UserProfile;
