import axios from 'axios';
import config from '../config';
// import jwtDecode from 'jwt-decode';

const APIClient = axios.create({
  baseURL: config.baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default APIClient;