import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const VerifyEmail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    // Extract id and token from URL query params
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const token = queryParams.get('token');

    const verifyEmail = async () => {
      try {
        // Send the request with query parameters in the URL
        await axios.post(`${process.env.FRONT_DEV_URL}/api/authentication/verify-email?id=${id}&token=${token}`);

        setMessage('Your email has been successfully verified!');
        setIsSuccess(true);
      } catch (error) {
        setMessage('Email verification failed. The token may be invalid or expired.');
        setIsSuccess(false);
      }
    };

    if (id && token) {
      verifyEmail();
    }
  }, [location]);

  const handleRedirect = () => {
    navigate('/loginpage');
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
      <div className="bg-white p-10 rounded-2xl shadow-2xl w-96 text-center">
        <h2 className={`text-2xl font-bold ${isSuccess ? 'text-gray-900' : 'text-red-500'} mb-6`}>
          {message}
        </h2>
        {isSuccess && (
          <button
            className="mt-6 w-full px-8 py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-lg hover:bg-blue-700 transition-all duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 focus:outline-none"
            onClick={handleRedirect}
          >
            Go to Login
          </button>
        )}
        {!isSuccess && (
          <p className="text-gray-600 mt-6">Please try again or contact support if the issue persists.</p>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
