import VerifyEmail from "../components/VerfiyEmail";

function VerifyEmailPage() {
    return (
      <div>
      <VerifyEmail />
      </div>
    );
  }
  
  export default VerifyEmailPage;
