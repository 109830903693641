import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'

export default function Example() {
  return (
    <div className="bg-gray-50 px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <p className="text-base font-semibold leading-7 text-[#3B725C]">Welcome to Our Pharmacy</p>
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Pharmacy Services at Your Fingertips</h1>
        <p className="mt-6 text-xl leading-8">
          At our pharmacy, we strive to make healthcare convenient and accessible for everyone. Whether you're refilling prescriptions, ordering supplements, or seeking health advice, we've got you covered.
        </p>
        <div className="mt-10 max-w-2xl">
          <p>
            Our mission is to ensure that you have access to all your healthcare needs. From over-the-counter products to expert consultation, we are here to support you every step of the way.
          </p>
          <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600">
            <li className="flex gap-x-3">
              <CheckCircleIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-[#3B725C]" />
              <span>
                <strong className="font-semibold text-gray-900">Prescription Management.</strong> Easily refill and manage your prescriptions online with secure, fast processing.
              </span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-[#3B725C]" />
              <span>
                <strong className="font-semibold text-gray-900">Health Supplements.</strong> We offer a wide range of vitamins, minerals, and supplements to support your well-being.
              </span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-[#3B725C]" />
              <span>
                <strong className="font-semibold text-gray-900">Expert Consultation.</strong> Our licensed pharmacists are available for consultations to help guide your health decisions.
              </span>
            </li>
          </ul>
          <p className="mt-8">
            With our user-friendly platform, managing your health has never been easier. Enjoy fast delivery, secure transactions, and professional service, all from the comfort of your home.
          </p>
          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Why Choose Our Pharmacy?</h2>
          <p className="mt-6">
            We are dedicated to providing personalized healthcare solutions. Our pharmacy is built on trust, professionalism, and customer care. With a focus on timely delivery and quality products, we ensure that your health is always our top priority.
          </p>
          <figure className="mt-10 border-l border-[#3B725C] pl-9">
            <blockquote className="font-semibold text-gray-900">
              <p>
                “The best pharmacy experience I've ever had! Fast, convenient, and extremely reliable service. I highly recommend them for all your healthcare needs.”
              </p>
            </blockquote>
            <figcaption className="mt-6 flex gap-x-4">
              <img
                alt=""
                src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                className="h-6 w-6 flex-none rounded-full bg-gray-50"
              />
              <div className="text-sm leading-6">
                <strong className="font-semibold text-gray-900">John Doe</strong> – Satisfied Customer
              </div>
            </figcaption>
          </figure>
          <p className="mt-10">
            We're here for you 24/7 with our easy-to-use online platform, providing you with the best products and services in the healthcare industry.
          </p>
        </div>
        <figure className="mt-16">
          <img
            alt="Pharmacy online platform"
            src="images/family.jpg"
            className="aspect-video rounded-xl bg-gray-50 object-cover"
          />
          <figcaption className="mt-4 flex gap-x-2 text-sm leading-6 text-gray-500">
            <InformationCircleIcon aria-hidden="true" className="mt-0.5 h-5 w-5 flex-none text-gray-300" />
            Offering a wide range of healthcare products and services to meet your needs.
          </figcaption>
        </figure>
        <div className="mt-16 max-w-2xl">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900">Your Health is Our Priority</h2>
          <p className="mt-6">
            From prescription refills to personalized consultations, we provide everything you need to manage your health efficiently. Explore our vast selection of healthcare products, and take advantage of our fast, secure delivery service.
          </p>
          <p className="mt-8">
            Thank you for choosing our pharmacy. We are committed to providing exceptional healthcare services and ensuring that your health and wellness are always prioritized.
          </p>
        </div>
      </div>
    </div>
  )
}
