import React from 'react';
import Nav from '../components/Nav';
import Login from '../components/Authentication/Login';
import Footer from '../components/Footer';


function LoginPage() {
  return (
    <div>
      <Nav />
      <Login />
      <Footer />
    </div>
  );
}

export default LoginPage;
